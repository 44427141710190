.about-banner-div1 {
  background: #00212a;
  min-height: 86vh;
  background-repeat: no-repeat;
  background-size: cover;
  /* background-position-x: -60px; */
  padding-top: 165px;
  position: relative;
  overflow-x: clip;
  max-width: 100%;
}

.about-para-1 {
  color: #e1e1e1;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 23px; /* 143.75% */
}

.about-banner-img23 {
  position: absolute;
  z-index: 5;
  bottom: -200px;
  left: -200px;
  transform: rotate(-6deg);
}

.about-map-pic {
  margin-top: -70px;
}

.about-map-text1 {
  color: #000;
  font-family: "Bebas Neue";
  font-size: 130px;
  font-style: normal;
  font-weight: 400;
  line-height: 61.13px; /* 47.023% */
  margin-bottom: 70px !important;
  text-align: center;
}

.about-truck-img12 {
  padding-top: 50px;
  padding-bottom: 50px;
}

.movers-maindiv1 {
  overflow: hidden;
}

.movers-div1 {
  margin-top: 50px;
  margin-bottom: 70px;
  background: linear-gradient(to right, #fff 25%, #070707 20%);
  position: relative;
}

.movers-img-div1 {
  padding-top: 65px;
  padding-bottom: 65px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fdf2dd;
  transform: translateY(50px);
}

.movers-text1 {
  color: #fff;
  font-family: "Bebas Neue";
  font-size: 56px;
  font-style: normal;
  font-weight: 400;
  line-height: 56px; /* 100% */
  padding-left: 80px;
  padding-right: 80px;
}

.movers-text1 span {
  color: #f7b53c;
  font-family: "Bebas Neue";
  font-size: 56px;
  font-style: normal;
  font-weight: 400;
  line-height: 56px;
}

.movers-para-12 {
  color: #f2f2f2;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 27px; /* 168.75% */
   padding-left: 80px;
  padding-right: 80px;

}

/* @media screen and (max-width: 1330px) { */
.movers-img-div1 div img {
  width: 100%;
  height: auto;
  transform: translateX(-10px);
}










.about-last-sec-div1{
        padding-top: 70px;
        padding-bottom: 100px;
    }

    .about-last-sec-div1 div div p{
        color: #000;
font-family: Poppins;
font-size: 20px;
font-style: normal;
font-weight: 400;
line-height: 27px; /* 135% */
    }

    
    .about-last-sec-div1 div div h2{
        color: #000;
font-family: "Bebas Neue";
font-size: 64px;
font-style: normal;
font-weight: 400;
line-height: 65.219px; /* 101.905% */
margin-top: 45px;
margin-bottom: 25px !important;
    }

    
    .about-last-sec-div1 div div h2 span{
        color: #F7B53C;
font-family: "Bebas Neue";
font-size: 64px;
font-style: normal;
font-weight: 400;
line-height: 65.219px;
    }

























@media screen and (max-width: 576px) {

.about-banner-div1 {
  min-height: 30vh;
  padding-bottom: 120px;
  padding-top: 0px;
  max-width: 100%;
  padding-left: 0px;
  padding-right: 0px;
}

.for-mobile-about-banner-col{
    
  padding-left: 0px;
  padding-right: 0px;
}

.about-para-1 {
  font-size: 13px;
  line-height: 20px; /* 143.75% */
  padding-left: 30px;
  padding-right: 30px ;
  text-align: center;
}

.about-banner-img23 {
  position: absolute;
  z-index: 7;
  bottom: -180px;
  left: 60px;
  /* transform: translateX(-50); */
  transform: rotate(-5deg);
  width: 300px;
  height: 174px;
}

.about-map-pic {
  margin-top: 30px;
}



.about-map-text1 {
  font-size: 54px;
  line-height: 50px; /* 47.023% */
  margin-bottom: 40px !important;
  text-align: center;
}













.about-last-sec-div1{
        padding-top: 0px;
        padding-bottom: 0px;
        margin-top: -50px;
    }

    .about-last-sec-div1 div div p{
font-size: 13px;
line-height: 20px; /* 135% */
text-align: center;
padding: 0px 15px;
    }

    
    .about-last-sec-div1 div div h2{
font-size: 36px;
line-height: 36px; /* 101.905% */
margin-top: 45px;
margin-bottom: 20px !important;
text-align: center;
    }

    
    .about-last-sec-div1 div div h2 span{
font-size: 36px;
line-height: 36px;
text-align: center;
    }


      .about-last-sec-div1 div div img{
      margin-top:20px;
    }













    
.movers-maindiv1 {
  overflow: hidden;
}

.movers-div1 {
  margin-top: 0px;
  margin-bottom: 0px;
  background:  #070707 ;
  position: relative;
  padding: 50px;
}

.movers-img-div1 {
  display: none;
}

.movers-text1 {
  font-size: 34px;
  line-height: 34px; /* 100% */
  text-align: center;
   padding-left: 0px;
  padding-right: 0px;

}

.movers-text1 span {
  font-size: 34px;
  line-height: 34px;
  text-align: center;
}

.movers-para-12 {
  font-size: 13px;
  line-height: 20px; /* 168.75% */
  text-align: center;
   padding-left: 0px;
  padding-right: 0px;

}

/* @media screen and (max-width: 1330px) { */
.usa-movers-div233 div div img {
  width: 100%;
  height: auto;
  margin-top: 50px;
}

}





.about-truck-img12{
  overflow: hidden;
}

.truck-img-animation{
  position: relative;
    animation: moveTruck 16s linear infinite; /* Total animation time increased to accommodate the stop */
  }

  @keyframes moveTruck {
    0% {
      left: -125%; /* Start from left */
    }
    31.25% {
      left: calc(50% ); /* Stop in center (25% of total animation time) */
      transform: translateX(-50%);
    }
    68.75% {
      left: calc(50% ); /* Stay in center (45% of total animation time) */
      transform: translateX(-50%);
    }
    100% {
      left: calc(110%); /* Move to right (100% of total animation time) */
    }
  }




