.footer-text-div12 {
  background: #070707;
  min-height: 100%;
  padding: 90px;
}

.footer-img-div12 {
  min-width: 100%;
}

.footer-text-div12 div h2 {
  color: #fff;
  font-family: "Bebas Neue";
  font-size: 64px;
  font-style: normal;
  font-weight: 400;
  line-height: 65.219px; /* 101.905% */
}

.footer-text-div12 div h2 span {
  color: #f7b53c;
  font-family: "Bebas Neue";
  font-size: 64px;
  font-style: normal;
  font-weight: 400;
  line-height: 65.219px;
}

.footer-email-div1 {
  display: flex;
  align-items: center;
  column-gap: 20px;
  margin-top: 35px;
}

.footer-email-div1 div p {
  color: #e0e0e0;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 105% */
  margin-bottom: 9px !important;
}

.footer-email-div1 div h5 {
  color: #fff;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 15px; /* 95.455% */
}

.footer-last-sec-text-div56 {
  background: #2f2f2f;
  padding-top: 18px;
  padding-bottom: 18px;
  min-width: 100%;
  position: absolute;
  bottom: 0px;
  left: 0px;
}

.footer-last-sec-text-div56 div:first-child {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.footer-last-sec-text-div56 div p {
  color: #e0e0e0;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 150% */
  margin-bottom: 0px;
}

.footer-last-sec-text-div56 div p a {
  color: #e0e0e0 !important;
}

.footer-last-sec-text-div89 {
  display: flex;
  column-gap: 5px;
}

.footer-last-sec-text-div89 div {
}

@media screen and (max-width: 1300px) {
  .footer-text-div12 {
    padding: 70px;
  }
}

@media screen and (max-width: 576px) {
  .footer-img-div12 {
    min-width: 10%;
    max-width: 100%;
    height: auto;
  }

  .footer-text-div12 {
    padding: 60px;
    padding-bottom: 130px;
  }

  .footer-text-div12 div h2 {
    font-size: 42px;
    line-height: 44px; /* 101.905% */
  }

  .footer-text-div12 div h2 span {
    font-size: 42px;
    line-height: 44px;
  }

  .footer-email-div1 {
    display: flex;
    align-items: center;
    column-gap: 20px;
    margin-top: 35px;
  }

  .footer-email-div1 div p {
    font-size: 20px;
    line-height: 20px; /* 105% */
    margin-bottom: 9px !important;
  }

  .footer-email-div1 div h5 {
    font-size: 22px;
    line-height: 22px; /* 95.455% */
    margin-bottom: 0px !important;
  }

  .footer-last-sec-text-div56 {
    padding: 20px !important;
  }

  .footer-last-sec-text-div89 svg {
    width: 27px;
    height: 27px;
  }

  .footer-last-sec-text-div56 div p {
    color: #e0e0e0;
    font-family: Poppins;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 15px; /* 150% */
    margin-bottom: 0px;
    text-align: center;
  }
}
