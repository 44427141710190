@import url("https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Inter:wght@100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

a {
  text-decoration: none !important;
}

.navbar-div1 {
  position: absolute;
  width: 100% !important;
  z-index: 9;
  /* background: rgba(0, 0, 0, 0.1); */
  padding-top: 14px;
  padding-bottom: 14px;
}

.navbar-logo1 {
}

.navbar-ul-div1 {
  display: flex !important;
  justify-content: center;
}

.navbar-ul1 {
  column-gap: 25px;
  display: flex;
  align-items: center;
}

.navbar-ul1 .nav-item {
  display: flex;
  align-items: center;
}

.navbar-ul1 .nav-item .nav-link {
  color: #fff;
  text-align: center;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 52px; /* 288.889% */
}

.navbar-ul1 .nav-item .nav-link:hover {
  color: #f7b53c;
}

.navbar-ul1 .nav-item p {
  margin-bottom: 0px !important;
  color: #fff;
  text-align: center;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 250;
  line-height: 52px; /* 288.889% */
}

.navbar-contact-btn1 {
  color: #01070c;
  font-family: "Bebas Neue";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  margin-bottom: 0px !important;
  width: 178px;
  height: 56px;
  flex-shrink: 0;
  background: linear-gradient(
    95deg,
    #f7b53c 0.98%,
    #f7b53c 42.72%,
    #ffcd73 90.99%
  );
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 7px;
}

.mobile-navbar-div1 {
  background-color: white !important;
  min-height: 80px;
  width: 100%;
  z-index: 9999;
}

@media screen and (max-width: 991px) {
  /* .navbar-div1 {
        display: none;
    } */

  .mobile-navbar-div1 {
    display: block;
    width: 100%;
    top: 0px;
    left: 50%;
    z-index: 9999;
    transform: translatex(-50%);
  }
}

.mobile-nav-toogler {
  border: none;
  /* background: linear-gradient(95deg, #F7B53C 0.98%, #F7B53C 42.72%, #FFCD73 90.99%); */
  padding: 0px;
}

.mobile-nav-item .nav-link {
  color: #101010;
  font-family: Poppins;
  font-size: 20.667px;
  font-style: normal;
  font-weight: 400;
  line-height: 23.526px; /* 167.062% */
  letter-spacing: -0.207px;
  margin-left: 0px !important;
}

.mobile-nav-item a .nav-card {
  margin-top: 12px;
  margin-left: 0px !important;
}

.mobile-nav-item.home1 .mobilenav-lines-svg {
  min-width: 100%;
  height: 0.5px;
  background: #000 !important;
  margin-top: 10px;
  margin-bottom: 20px;
}

.mobile-nav-item .nav-link:hover {
  color: #101010 !important;
}

.mobile-navbar-div2 {
  /* background: #EAEFFF; */
  background-color: #fff;
  padding-top: 15px;
  padding-bottom: 30px;
  width: 100%;
  position: absolute;
  left: 0px;
  top: 0px;
  padding-right: 30px;
  z-index: 3000 !important;
  padding-left: 30px;
  display: none !important;
  animation: fade 0.5s ease-in-out;
  /* box-shadow: 1px 1px 10px rgb(131, 131, 131); */
}

.mobile-navbar-div2.show {
  /* background: #EAEFFF; */
  background-color: #fff;
  padding-top: 30px;
  padding-bottom: 30px;
  padding-left: 30px;
  padding-right: 30px;
  width: 100%;
  position: absolute;
  left: 0px;
  top: 90px;
  transition: none;
  z-index: 9999;
  /* transform: translatey(0%) !important; */
  /* transition: all 0.5s ease-in-out; */
  display: block !important;
  animation: fade 0.5s ease-in-out;
  border-radius: 7px;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}

@keyframes fade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.mobile-nav-item.active .nav-link {
  color: black !important;
}

@media screen and (max-width: 576px) {
  .nav-text1,
  .dark-nav-text1 {
    font-size: 16px;
  }

  .navbar-contact-btn1 {
    color: #000;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    width: 110px;
    height: 36px;
    flex-shrink: 0;
    border-radius: 23px;
    background: linear-gradient(90deg, #40e570 0.13%, #84ffa8 99.68%);
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
    margin-top: 5px;
  }
}

.mobile-call-btn1 {
  background: linear-gradient(
    95deg,
    #f7b53c 0.98%,
    #f7b53c 42.72%,
    #ffcd73 90.99%
  );
  padding: 12px;
}

.nav-mobile-top-text-div {
  border-top: 0.577px solid rgba(162, 162, 162, 0.2);
  border-right: 0.577px solid rgba(162, 162, 162, 0.2);
  border-left: 0.577px solid rgba(162, 162, 162, 0.2);
  background: #f4f4f4;
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-width: 100%;
  padding-left: 17px;
  padding-bottom: 7px;
  min-height: 100%;
}

.nav-mobile-top-text-div div h2 {
  color: #000;
  font-family: "Bebas Neue";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 20.377px; /* 196.536% */
  margin-bottom: 3px !important;
  margin-top: 12px;
}

.nav-mobile-top-text-div div h3 {
  color: #f7b53c;
  font-family: "Bebas Neue";
  font-size: 26px;
  font-style: normal;
  font-weight: 400;
  margin-bottom: 0px !important;
  line-height: 26.781px; /* 160.697% */
}

.nav-mobile-top-text-div div h3 span {
  color: #000;
  font-family: "Bebas Neue";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 13.809px; /* 298.633% */
}

.mobile-qoute-btn122 {
  background: linear-gradient(
    95deg,
    #f7b53c 0.98%,
    #f7b53c 42.72%,
    #ffcd73 90.99%
  );
  width: 100%;
  color: #fff;
  text-align: center;
  font-family: "Bebas Neue";
  font-size: 26px;
  font-style: normal;
  font-weight: 400;
  padding-top: 14px;
  padding-bottom: 12px;
  margin-top: 10px;
}

.mobile-qoute-btn111 {
  border: 1px solid #5d5d5d;
  width: 100%;
  color: #000;
  text-align: center;
  font-family: "Bebas Neue";
  font-size: 28px;
  line-height: 28px; /* 180.074% */
  padding: 14px;
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-bottom: 0px;
}

.mobile-qoute-btn111 div {
  background-color: #000;
  padding: 9px;
  padding-bottom: 5px;
  position: absolute;
  top: 7px;
  left: 7px;
}

.navbar-light .navbar-nav .nav-link:focus,
.navbar-light .navbar-nav .nav-link:hover {
  color: #fff;
}

.dropdown-menu {
  padding: 15px;
  left: 50% !important;
  transform: translateX(-50%) !important;
  animation: fade 0.5s ease-in-out;
  z-index: 10000;
}

.dropdown-menu-div12 {
  display: flex;
  column-gap: 15px;
  z-index: 9999;
}

.nav-desk-top-img-div {
  height: 175px;
}

.nav-desk-top-text-div {
  border-top: 0.577px solid rgba(162, 162, 162, 0.2);
  border-right: 0.577px solid rgba(162, 162, 162, 0.2);
  border-left: 0.577px solid rgba(162, 162, 162, 0.2);
  background: #f4f4f4;

  min-width: 100%;
  padding-left: 18px;
  padding-bottom: 13px;
  min-height: 100%;
}

.nav-cargo-text12 {
  color: #000 !important;
  font-family: "Bebas Neue" !important;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  margin-bottom: 0px !important;
  margin-top: 12px;
  width: 120px;
  height: 40px;
  background-color: #f7b53c;
  display: flex;
  align-items: center;
  justify-content: center;
}

.nav-desk-top-text-div div h2 {
  color: #000;
  font-family: "Bebas Neue";
  font-size: 28px;
  font-style: normal;
  font-weight: 400;
  line-height: 27.377px; /* 196.536% */
  margin-bottom: 3px !important;
  margin-top: 12px;
}

.nav-desk-top-text-div div h3 {
  color: #f7b53c;
  font-family: "Bebas Neue";
  font-size: 28px;
  font-style: normal;
  font-weight: 400;
  margin-bottom: 0px !important;
  line-height: 26.781px; /* 160.697% */
  margin-top: 10px;
}

.nav-desk-top-text-div div h3 span {
  color: #000;
  font-family: "Bebas Neue";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 13.809px; /* 298.633% */
}

.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.active,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .show > .nav-link {
  color: #fff !important;
}
