
.home-choose-div1 {
  padding-top: 100px;
  padding-bottom: 100px;
  background: #f9f9f9;
}

.home-choose-text1 {
  color: #000;
  text-align: center;
  font-family: "Bebas Neue";
  font-size: 64px;
  font-style: normal;
  font-weight: 400;
  line-height: 65.219px; /* 101.905% */
}

.home-choose-text1 span {
  color: #f7b53c;
  font-family: "Bebas Neue";
  font-size: 64px;
  font-style: normal;
  font-weight: 400;
  line-height: 65.219px;
}

.home-choose-text2 {
  color: #171717;
  text-align: center;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px; /* 166.667% */
}

.home-choose-row-div1 {
  row-gap: 50px;
  margin-top: 60px;
}

.home-choose-col-div1 {
}

.home-choose-col-div1 div h4 {
  color: #1e1e1e;
  text-align: center;
  font-family: Poppins;
  font-size: 23px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-top:20px;
}

.home-choose-col-div1 div p {
  color: #8e8f91;
  text-align: center;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px; /* 131.25% */
  padding-left:15px;
  padding-right: 15px;
}

.home-svg-div12 {
  height: 84px;
  display: flex;
  align-items: end;
  justify-content: center;
}

.home-form-div1 {
  background-image: url(../Images/aerial-view-container-cargo-ship-sea\ 2.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 60px;
  min-height: 500px;
}

.home-form-col-div1{
min-height: 100%;
}

.home-form-col-div1 div h2 {
  color: #f3f5f6;
  font-family: "Bebas Neue";
  font-size: 70px;
  font-style: normal;
  font-weight: 400;
  line-height: 64px; /* 91.429% */
  margin-top: -45px;
}

.home-form-col-div1 div h2 span {
  color: #f7b53c;
  font-family: "Bebas Neue";
  font-size: 70px;
  font-style: normal;
  font-weight: 400;
  line-height: 64px;
}

.form-request-text-div1 {
  background: #f7b53c;
  width: 100%;
  height: 70px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.form-request-text-div1 h2 {
  color: #000;
  font-family: Poppins;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: 38px; /* 228.267% */
  text-align: center;
  margin-top: 5px;
}

.form-main-div1 {
    min-height: 370px;
  padding: 40px 30px;
  border-top: 2.196px solid #f7b53c;
  border-right: 2.196px solid #f7b53c;
  border-left: 2.196px solid #f7b53c;
  background: #fff;
  box-shadow: 0px -40px 31.2px 0px rgba(0, 0, 0, 0.1);
}

.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 10px);
  padding: 0.375rem 0.75rem;
  background: #f3f5f6;
  background-clip: padding-box;
  border: none;
  border-radius: 0.05rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  color: #979797;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 200.474% */
  letter-spacing: -0.131px;
}

.form-check1 {
  border: 1.307px solid #8b5f0d;
  background: #AB7718;
  padding: 8px 33px 10.5px 10px;
  margin-top: 13px;
  padding-left: 33px !important;
  display: flex;
  align-items: center;
}



.form-check-label {
  color: #fff;
  font-family: Poppins;
  font-size: 15.5px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: -0.157px;
  margin-bottom: 0px !important;
  /* line-height: 17px; */
  margin-top: 4.5px;
  margin-left: 10px;
}

.form-check-input1 {
  width: 22.955px;
  height: 22.955px;
  flex-shrink: 0;
  border: 2px solid #fff;
  padding: 5px;
  background: transparent !important;
  opacity: 0;
  position: absolute;
  margin-left: -20px;
  z-index: 4;
}

.form-check1 span {
  display: block;
  width: 22.8px;
  height: 22.8px;
  position: absolute;
  flex-shrink: 0;
  border: 1px solid #fff;
  padding: 5px;
  background: transparent !important;
  margin-left: -20px;
  margin-top: 05px;
}

.form-check1 span::after {
  content: "";
  display: block;
  width: 16px;
  height: 16px;
  background-color: white;
  position: absolute;
  flex-shrink: 0;
  border: 1px solid #fff;
  padding: 5px;
  margin-left: -20px;
  margin-top: 04.5px;
  left: 22px;
  top: -02.5px;
  opacity: 0;
}

input:checked ~ span::after {
opacity: 1;
width: 16px;
  height: 16px;
}





.form-not-check1 {
  border: 1.307px solid #FFF4E1;
  background: #FFF4E1;
  padding: 8px 33px 10.5px 10px;
  margin-top: 13px;
  padding-left: 33px !important;
  display: flex;
  align-items: center;
}

.form-not-check1 .form-check-label {
  color: #585858;
  font-family: Poppins;
  font-size: 15.5px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: -0.157px;
  margin-bottom: 0px !important;
  /* line-height: 17px; */
  margin-top: 4.5px;
  margin-left: 10px;
}

.form-not-check1 span {
  display: block;
  width: 22.8px;
  height: 22.8px;
  position: absolute;
  flex-shrink: 0;
  border: 1px solid #000;
  padding: 5px;
  background: transparent !important;
  margin-left: -20px;
  margin-top: 04.5px;
}


.form-send-text1{
  color: #000;
font-family: Poppins;
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: normal;
display: flex;
align-items: center;
justify-content: center;
column-gap: 10px;
margin-bottom: 0px !important;
margin-top: 22px;
}

.form-thank-div31{
  min-height: 220px;
}


.form-thank-text1{
  color: #000;
font-family: Poppins;
font-size: 46px;
font-style: normal;
font-weight: 700;
text-transform: uppercase;
margin-top: 15px;
}


.form-thank-text2{
  color: #000;
font-family: Poppins;
font-size: 12px;
font-style: normal;
font-weight: 500;
}





.home-form-btn-div12{
color: #FFF;
text-align: center;
font-family: "Bebas Neue";
font-size: 30px;
font-style: normal;
font-weight: 400;
margin-bottom: 0px !important ;
background: linear-gradient(95deg, #F7B53C 0.98%, #F7B53C 42.72%, #FFCD73 90.99%);
min-width: 100%;
padding-top: 9px;
padding-bottom: 7px;
margin-top: 35px;
border: none !important;
}


.form-list-div1{
background: #F1F1F1;
min-width: 100%;
padding-top: 18px;
padding-bottom: 18px;
display: flex;
justify-content: space-evenly;
margin-top: 16px;
}

.form-list-div1 div{
display: flex;
align-items: center;
column-gap: 7px;
}

.form-list-div1 div p{
color: #000;
font-family: Poppins;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 20.082px; 
margin-bottom: 0px !important;

}



















.home-faqs-div1 {
  padding-top: 110px;
  padding-bottom: 110px;
}

.home-faqs-text1 {
  color: #000;
text-align: center;
font-family: "Bebas Neue";
font-size: 64px;
font-style: normal;
font-weight: 400;
line-height: 65.219px; /* 101.905% */
}

.home-faqs-text1 span{
  color: #F7B53C;
font-family: "Bebas Neue";
font-size: 64px;
font-style: normal;
font-weight: 400;
line-height: 65.219px;
}

.home-faqs-para1{
    color: #171717;
font-family: Poppins;
font-size: 18px;
font-style: normal;
font-weight: 400;
line-height: 30px; /* 166.667% */
text-align: center;
}

.home-faq-div2 {
  margin-top: 55px;
}

.home-faq-div2 .row .col-lg-6{
  padding: 0px 25px;
}

.faq-div1 {
  border-bottom: 1.061px solid #8E8F91;
  padding: 16px 0px;
}

.faq-question-div1 {
  justify-content: space-between;
}

.faq-text1 {
  color: #000;
font-family: Poppins;
font-size: 19px;
font-style: normal;
font-weight: 400;
line-height: 25px; /* 100% */
  margin-bottom: 0px !important;
  padding-right: 50px;
}

.faq-text1:hover {
  cursor: pointer;
}

.faq-question-div1 svg {
  cursor: pointer;
}

.faq-answer-div1 {
  margin-top: 30px;
  margin-bottom: 20px;
}

.faq-text2 {
  color: #000;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}
























.home-review-div1{
    background: #FFFCF7;
    padding-top: 90px;
    padding-bottom: 90px;
}

.home-review-text-div1{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.home-review-text-div1 div h2{
color: #000;
text-align: center;
font-family: "Bebas Neue";
font-size: 64.871px;
font-style: normal;
font-weight: 400;
line-height: 66.107px; /* 101.905% */
}

.home-review-text-div1 div h2 span{
color: #F7B53C;
font-family: "Bebas Neue";
font-size: 64.871px;
font-style: normal;
font-weight: 400;
line-height: 66.107px;
}

.home-review-text-div22{
    display: flex;
    align-items: center;
   column-gap: 15px;
}

.home-review-text-div22 p:first-child{
    display: flex;
padding: 11px 20px;
flex-direction: column;
justify-content: center;
align-items: center;
border-radius: 70px;
border: 1px solid #000;
background: #FFF6E7;
}

.home-review-text-div22 p:second-child{
color:  #242533;
font-family: Inter;
font-size: 16px;
font-style: normal;
font-weight: 400;
}


.home-review-text-div22 p:last-child{
    display: flex;
padding: 11px 20px;
flex-direction: column;
justify-content: center;
align-items: center;
border-radius: 70px;
border: 1px solid #000;
background: #FFE1AB;
}


.home-review-card-rowdiv1{
    margin-top: 55px;
}


.home-review-col-div1 div{
border: 1.064px solid #DADADA;
background: #FFF;
padding: 60px;
}

.home-review-col-div1 div h2{
color: #000;
font-family: "Bebas Neue";
font-size: 40px;
font-style: normal;
font-weight: 400;
}

.home-review-col-div1 div h2 span{
color: #F7B53C;
font-family: "Bebas Neue";
font-size: 40px;
font-style: normal;
font-weight: 400;
}

.home-review-col-div1 div p{
color: #313131;
font-family: Poppins;
font-size: 18px;
font-style: normal;
font-weight: 400;
line-height: 24px; /* 154.893% */
margin-top: 12px;
}

.home-review-col-div1 div h3{
    color: #1E1E1E;
font-family: "Bebas Neue";
font-size: 36.966px;
font-style: normal;
font-weight: 400;
line-height: normal;
margin-top: 29px;
}


.swiper {
    overflow: visible !important;
    overflow-x: clip !important;
   
}

.swiper .col-12{
margin-top: 50px  !important;
   
}


.swiper-button-next{
    right: 0px !important;
    top: -70px !important;
    width: 100px !important;
    height: 100px !important;

}

.swiper-button-next:after{
    /* content:'prev' !important; */
    content: url('data:image/svg+xml, <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" ><path fill-rule="evenodd" clip-rule="evenodd" d="M14.8344 16.8881L19.3824 12.3401C19.5776 12.1448 19.5776 11.8283 19.3824 11.633L14.8524 7.10299C14.6571 6.90773 14.3405 6.90773 14.1452 7.10299C13.95 7.29825 13.95 7.61483 14.1452 7.8101L17.8248 11.4896C17.8061 11.4876 17.7871 11.4865 17.7679 11.4866L4.96965 11.5135C4.69351 11.5141 4.47012 11.7385 4.4707 12.0146C4.47129 12.2907 4.69562 12.5141 4.97176 12.5135L17.77 12.4866C17.7885 12.4865 17.8068 12.4855 17.8248 12.4835L14.1272 16.181C13.932 16.3763 13.932 16.6928 14.1272 16.8881C14.3225 17.0834 14.6391 17.0834 14.8344 16.8881ZM18.2659 12.0423L18.3217 11.9866L18.2659 11.9308C18.2679 11.9487 18.2689 11.967 18.269 11.9855C18.269 12.0047 18.268 12.0237 18.2659 12.0423Z" fill="%23242533" /></svg>') !important;
  display: flex;
padding: 12px 24px;
flex-direction: column;
justify-content: center;
align-items: center;
border-radius: 70px;
border: 1px solid #000;
background: #FFE1AB;
line-height: 1px !important;
  }


.swiper-button-prev{
    right: 90px !important;
    top: -70px !important;
    width: 100px !important;
    height: 100px !important;

}

.swiper-button-prev, .swiper-rtl .swiper-button-next {
    left: auto !important;
    right: auto;
}

.swiper-button-prev:after{
    /* content:'prev' !important; */
    content: url('data:image/svg+xml, <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" ><path fill-rule="evenodd" clip-rule="evenodd" d="M9.16515 7.11202L4.61715 11.66C4.42189 11.8553 4.42189 12.1719 4.61715 12.3671L9.14716 16.8971C9.34242 17.0924 9.659 17.0924 9.85426 16.8971C10.0495 16.7019 10.0495 16.3853 9.85426 16.19L6.17473 12.5105C6.19339 12.5126 6.21236 12.5136 6.23158 12.5136L19.0289 12.4866C19.3051 12.486 19.5285 12.2617 19.5279 11.9855C19.5273 11.7094 19.303 11.486 19.0268 11.4866L6.22948 11.5136C6.21097 11.5136 6.19271 11.5147 6.17473 11.5167L9.87226 7.81913C10.0675 7.62387 10.0675 7.30728 9.87226 7.11202C9.677 6.91676 9.36042 6.91676 9.16515 7.11202ZM5.73361 11.9578L5.67781 12.0136L5.73361 12.0694C5.73161 12.0514 5.73057 12.0331 5.73053 12.0146C5.73049 11.9954 5.73153 11.9764 5.73361 11.9578Z" fill="%23242533" /></svg>') !important;
      display: flex;
padding: 12px 24px;
flex-direction: column;
justify-content: center;
align-items: center;
border-radius: 70px;
border: 1px solid #000;
/* background: #FFE1AB; */
background: #FFF6E7;
line-height: 1px !important;
  }























@media screen and (max-width: 576px) {
.home-choose-div1 {
  padding-top: 70px;
  padding-bottom: 70px;
}

.home-choose-text1 {
  font-size: 34px;
  line-height: 34px; /* 101.905% */
}

.home-choose-text1 span {
  font-size: 34px;
  line-height: 34px;
}

.home-choose-text2 {
  font-size: 12px;
  line-height: 20px; /* 166.667% */
}

.home-choose-row-div1 {
  row-gap: 10px;
  margin-top: 40px;
}

.home-choose-col-div1 {
}


.home-svg-div12{
height: 60px;
display: flex;
align-items: end;
}

.home-svg-div12 img{
width: 50px;
}

.home-choose-col-div1 div h4 {
  font-size: 13px;
  margin-top: 13px;
  margin-bottom: 4px;
}

.home-choose-col-div1 div p {
  font-size: 10px;
  line-height: 17px;
}

















.home-form-div1 {
  background-image: url(../Images/aerial-view-container-cargo-ship-sea\ 2.png);
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 45px;
  height: 150px;
  
    background-position-y: -100px;
}


.home-form-col-div1 div h2 {
  font-size: 42px;
  text-align: center;
  line-height: 42px; /* 91.429% */
  padding-left: 10px;
  padding-right: 10px;
  margin-top: 0px;
}

.home-form-col-div1 div h2 span {
  font-size: 42px;
  line-height: 42px;
  text-align: center;

}


.form-request-text-div1 h2 {
  font-size: 24px;
  line-height: 24px; /* 228.267% */
  text-align: center;
  margin-top: 5px;
}


.form-request-text-div1 {
    width: 100%;
    height: 62px;
    margin-top: 60px;
}


.form-main-div1 {
    min-height: 70px;
    padding: 30px 20px 0px 20px;
}



.form-control {
  
  font-size: 14px;
  line-height: 20px; /* 200.474% */
  letter-spacing: -0.131px;
}

.form-check1 {
  padding: 8px 33px 10.5px 10px;
  margin-top: 13px;
  padding-left: 25px !important;
}

.form-check-label {
    font-size: 12px;
    margin-bottom: 0px !important;
    /* line-height: 17px; */
    margin-top: 4.5px;
    margin-left: 10px;
}

.form-check-input1 {
  width: 18px;
  height: 18px;
  margin-left: -15px;
  z-index: 4;
}

.form-check1 span {
  display: block;
  width: 18px;
  height: 18px;
  margin-left: -15px;
  margin-top: 04.5px;
}

.form-check1 span::after {
  width: 10px;
  height: 10px;
  padding: 5px;
  margin-left: -15px;
  margin-top: 04.5px;
  left: 17.4px;
  top: -2.5px;
  opacity: 0;
}

input:checked ~ span::after {
opacity: 1;
width: 10px;
  height: 10px;
}






.form-not-check1 {
  padding: 8px 33px 10.5px 10px;
  margin-top: 13px;
  padding-left: 25px !important;
}

.form-not-check1 .form-check-label {
    font-size: 12px;
    margin-bottom: 0px !important;
    /* line-height: 17px; */
    margin-top: 4.5px;
    margin-left: 10px;
}


.form-not-check1 span {
  display: block;
  width: 18px;
  height: 18px;
  margin-left: -15px;
  margin-top: 04.5px;
}

.form-not-check1 span::after {
  width: 10px;
  height: 10px;
  padding: 5px;
  margin-left: -15px;
  margin-top: 04.5px;
  left: 17px;
  top: -2.5px;
  opacity: 0;
}

input:checked ~ span::after {
opacity: 1;
width: 10px;
  height: 10px;
}



.input2-field-div{
  margin-top: 13px;
}


.home-form-btn-div12{
font-size: 22px;
line-height: 22px;
background: linear-gradient(95deg, #F7B53C 0.98%, #F7B53C 42.72%, #FFCD73 90.99%);
min-width: 100%;
padding-top: 15px;
padding-bottom: 12px;
margin-top: 35px;
}




.form-send-text1{
  color: #000;
font-family: Poppins;
font-size: 12px;
font-style: normal;
font-weight: 500;
line-height: normal;
display: flex;
align-items: center;
justify-content: center;
column-gap: 10px;
margin-bottom: 0px !important;
margin-top: 18px;
}

.form-thank-div31{
  min-height: 180px;
}


.form-thank-text1{
  color: #000;
font-family: Poppins;
font-size: 38px;
font-style: normal;
font-weight: 700;
text-transform: uppercase;
margin-top: 15px;
}


.form-thank-text2{
  color: #000;
font-family: Poppins;
font-size: 12px;
font-style: normal;
font-weight: 500;
}








.form-list-div1{
padding-top: 18px;
padding-bottom: 18px;
display: flex;
justify-content: space-evenly;
flex-wrap: wrap;
margin-top: 13px;
row-gap: 10px;
column-gap:10px;
}

.form-list-div1 div{
display: flex;
align-items: center;
column-gap: 5px;
}

.form-list-div1 div p{
color: #000;
font-family: Poppins;
font-size: 10px;
font-style: normal;
font-weight: 400;
line-height: 10px; 
margin-bottom: 0px !important;

}




























.home-faqs-div1 {
  padding-top: 200px;
  padding-bottom: 70px;

}

.home-faqs-text1 {
font-size: 34px;
line-height: 34px; /* 101.905% */
}

.home-faqs-text1 span{
font-size: 34px;
line-height: 34px;
}

.home-faqs-para1{
font-size: 12px;
line-height: 20px; /* 166.667% */
text-align: center;
}

.home-faq-div2 {
  margin-top: 20px;
}

.home-faq-div2 .row .col-lg-6{
  padding: 0px 20px;
}

.faq-div1 {
  border-bottom: 1.061px solid #8E8F91;
  padding: 12px 0px;
}


.faq-text1 {
font-size: 13px;
line-height: 21px; /* 100% */
  margin-bottom: 0px !important;
  padding-right: 30px;
}

.faq-text1:hover {
  cursor: pointer;
}

.faq-question-div1 svg {
  cursor: pointer;
  width: 18px;
}

.faq-answer-div1 {
  margin-top: 20px;
  margin-bottom: 20px;
}

.faq-text2 {
  font-size: 12px;
}

.mobile-form-div23{
  padding: 0px;
}



}

















@media screen and (max-width: 354px) {
  .home-faqs-div1 {
  padding-top: 190px;
  padding-bottom: 70px;

}
}


@media screen and (max-width: 350px) {
  .home-faqs-div1 {
  padding-top: 210px;
  padding-bottom: 70px;

}
}





















































