@import url("https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Inter:wght@100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
 
.home-banner-div1 {
  background-image: url(../Images/Group\ 1000008664.jpg);
  min-height: 86vh;
  background-repeat: no-repeat;
  background-size: cover;
  /* background-position-x: -60px; */
  padding-top: 172px;
}

.home-banner-stars-div1 {
  border-radius: 50px;
  border: 1px solid #454545;
  background: rgba(37, 37, 37, 0.41);
  backdrop-filter: blur(12px);
  display: flex;
  flex-shrink: 0 !important;
  width: 367px;
  padding: 15px 27px 14px 25px;
  column-gap: 10px;
}

.home-banner-stars-div1 p {
  color: #fff;
  text-align: center;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.1; /* 985.591% */
  margin-bottom: 0px;
}

.home-banner-text1 {
  color: #fff;
  font-family: "Bebas Neue";
  font-size: 60px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.1; /* 101.884% */
  margin-top: 20px;
  text-align: left;
}

.home-banner-text1 span {
  font-family: "Bebas Neue";
  font-size: 64px;
  font-style: normal;
  font-weight: 400;
  line-height: 1; /* 132.24% */
  background: linear-gradient(
    95deg,
    #f7b53c 0.98%,
    #f7b53c 42.72%,
    #ffcd73 90.99%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.home-banner-ul-div1 {
  padding-left: 0px;
}

.home-banner-ul-div1 li {
  display: flex;
  align-items: center;
  column-gap: 10px;
}

.home-banner-ul-div1 li p {
  color: #e3e3e3;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.9; /* 281.763% */
  margin-bottom: 0px !important;
}

.banner-quote-btn1 {
  width: 338px;
  height: 58px;
  flex-shrink: 0;
  background: linear-gradient(
    95deg,
    #f7b53c 0.98%,
    #f7b53c 42.72%,
    #ffcd73 90.99%
  );
  color: #01070c;
  font-family: "Bebas Neue";
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 27px;
  padding-right: 27px;
  margin-bottom: 0px !important;
  margin-top: 40px;
}

.home-solution-div1 {
  padding-top: 100px;
  padding-bottom: 100px;
}

.home-solution-text1 {
  color: #000;
  font-family: "Bebas Neue";
  font-size: 100px;
  font-style: normal;
  font-weight: 400;
  line-height: 61.13px; /* 61.13% */
  text-align: center;
}

.home-solution-text2 {
  color: #191919;
  text-align: center;
  leading-trim: both;
  text-edge: cap;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 60px;
  margin-top: 20px;
}

.home-solution-col-div1 {
  border-top: 1.714px solid #a9a9a9;
  border-right: 1.714px solid #a9a9a9;
  border-left: 1.714px solid #a9a9a9;
  background: #fff;
  padding: 40px 60px 0px 60px;
  text-align: center;
}

.home-solution-col-div1 h2 {
  color: #01080d;
  text-align: center;
  font-family: "Bebas Neue";
  font-size: 62px;
  font-style: normal;
  font-weight: 400;
  margin-top: 45px;
  line-height: 1;
}

.home-solution-col-div1 h1 {
  color: #f7b53c;
  text-align: center;
  font-family: "Bebas Neue";
  font-size: 93px;
  font-style: normal;
  font-weight: 400;
  line-height: 1;
  letter-spacing: -0.93px;
}

.home-solution-btn1 {
  width: 100%;
  height: 70px;
  flex-shrink: 0;
  background: linear-gradient(
    95deg,
    #f7b53c 0.98%,
    #f7b53c 42.72%,
    #ffcd73 90.99%
  ) !important;
  color: #01080d;
  text-align: center;
  font-family: "Bebas Neue";
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 0px !important;
  border: none !important;
  margin-top: 45px;
  line-height: 1;
}

.home-sec3-card-div11 {
  background: #000;
  padding-top: 110px;
  padding-bottom: 100px;
}

.home-sec3-card-div22 {
  border: 1.059px solid #cbcbcb;
  border-top: 6px solid #f7b53c;
  opacity: 0.8;
  padding: 38px;
}

.home-sec3-card-div22 div {
  height: 45px;
  display: flex;
  align-items: center;
}

.home-sec3-card-div22 h2 {
  color: #fff;
  font-family: Poppins;
  font-size: 56px;
  font-style: normal;
  font-weight: 800;
  line-height: 56px; /* 157.168% */
  margin-top: 28px;
}

.home-sec3-card-div22 p {
  color: #b5b5b5;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.2; /* 525.518% */
  margin-top: 15px;
}

.home-idea-div1 {
  padding-top: 110px;
  padding-bottom: 90px;
}

.home-idea-text-div1 {
  display: flex;
  justify-content: space-between;
}

.home-idea-text-div2 h2 {
  color: #0c0c0d;
  font-family: "Bebas Neue";
  font-size: 94px;
  font-style: normal;
  font-weight: 400;
  line-height: 75.1px; /* 87.34% */
}

.home-idea-text-div2 h3 {
  color: #f7b53c;
  font-family: "Bebas Neue";
  font-size: 80px;
  font-style: normal;
  font-weight: 400;
  line-height: 75px; /* 102.625% */
}

.home-idea-btn1 {
  width: 268px;
  height: 74px;
  flex-shrink: 0;
  background: linear-gradient(
    95deg,
    #f7b53c 0.98%,
    #f7b53c 42.72%,
    #ffcd73 90.99%
  );
  color: #01080d;
  text-align: center;
  font-family: "Bebas Neue";
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 0px !important;
}

.home-idea-row-div11 {
  row-gap: 16px;
  margin-top: 75px;
}

.home-idea-row-div11 .col-lg-3 {
  padding-left: 8px !important;
  padding-right: 8px !important;
}

.home-idea-img-div11 {
  height: 265px;
  min-width: 100%;
}

.home-idea-img-div11 img {
  min-height: 100%;
}

.home-idea-btn-div11 {
  background: #fdc866;
  padding-top: 12px;
  padding-bottom: 12px;
  min-width: 100% !important;
  color: #000;
  font-family: "Bebas Neue";
  font-size: 30.642px;
  font-style: normal;
  font-weight: 400;
  text-align: center;
  position: relative;
  z-index: 3;
  text-transform: uppercase;
}





@media screen and (max-width: 1300px) {


.home-banner-div1 {
  
  background-position-x: -100px;
  
}

}










@media screen and (max-width: 576px) {


.home-mobile-top-div{
  padding-top: 12px;
  padding-bottom: 12px;
}



  .home-mobile-top-text-div{
border-top: 0.577px solid rgba(162, 162, 162, 0.20);
border-right: 0.577px solid rgba(162, 162, 162, 0.20);
border-left: 0.577px solid rgba(162, 162, 162, 0.20);
background: #F4F4F4;
display: flex;
align-items: center;
justify-content: space-between;
min-width: 100%;
padding-left: 13px;
padding-bottom: 5px;
min-height: 100%;
  }


  .home-mobile-top-col1-div{
    padding-right: 5px;
min-height: 100%;

  }

   .home-mobile-top-col2-div{
    padding-left: 5px;
min-height: 100%;

  }

  .home-mobile-top-text-div div h2{
    color: #000;
font-family: "Bebas Neue";
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 16.377px; /* 196.536% */
margin-bottom: 3px !important;
margin-top: 5px;
  }

   .home-mobile-top-text-div div h3{
    color: #F7B53C;
font-family: "Bebas Neue";
font-size: 22px;
font-style: normal;
font-weight: 400;
margin-bottom: 0px !important;
line-height: 17.781px; /* 160.697% */
  }

  .home-mobile-top-text-div div h3 span{
    color: #000;
font-family: "Bebas Neue";
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: 10.809px; /* 298.633% */
  }


















   
.home-banner-div1 {
  background-image: url(../Images/aerial-view-container-cargo-ship-sea\ \(2\)\ 1.jpg);

  min-height: 55vh;
  background-repeat: no-repeat;
  background-size: cover;
  background-position-x: 0px;
  padding-top: 0px;
  position: relative;
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.moblie-home-banner-div1{
padding: 0px;
min-height: 55vh;
min-width: 100%;
z-index: 8;
position: relative;
}

.for-mobile-responsive-homebanner{
  position: absolute;
  bottom: -30px;
  text-align: center;
  min-width: 100%;
  display: grid;
  place-items: center;
  place-content: center;
}

.home-banner-stars-div1 {
  border-radius: 0px;
  border: 1px solid #454545;
   background: #003135;
  width: 100%;
  padding: 15px 0px 15px 0px;
  column-gap: 10px;
  margin-bottom: 65px;
  text-align: center;
  justify-content: center;
  align-items: center;
}

.home-banner-stars-div1 p {
  color: #fff;
  text-align: center;
  font-family: Poppins;
  font-size: 10px;
}

.home-banner-text1 {
  font-size: 40px;
  margin-top: 20px;
  padding-left: 30px;
  padding-right: 30px ;
  text-align: center;
  
}

.home-banner-text1 span {
  font-size: 40px;
  text-align: center;
}

.banner-quote-btn1 {
    width: 326px;
    height: 58px;
    margin-top: 20px;
}







.mobile-home-icon-div1{
background: #FFF9EE;
padding-top: 66px;
padding-bottom: 23px;
}


.mobile-home-icon-div2 div div{
display: flex;
justify-content: center;
}

.mobile-home-icon-div2 p{
color: #000;
text-align: center;
font-family: Poppins;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 15px; /* 107.143% */
margin-top: 15px;
}















.home-sec3-card-div11 {
  background: #000;
  padding-top: 65px;
  padding-bottom: 65px;
}

.home-sec3-card-div11 .container .row .col-lg-3{
  padding-left: 05px !important;
  padding-right: 05px !important;
  margin-top: 15px;
}

.home-sec3-card-div22 {
  border: 1.059px solid #cbcbcb;
  border-top: 6px solid #f7b53c;
  opacity: 0.8;
  padding: 20px;
}

.home-sec3-card-div22 div {
  height: 45px;
  display: flex;
  align-items: center;
}

.home-sec3-card-div22 h2 {
  color: #fff;
  font-family: Poppins;
  font-size: 35px;
  font-style: normal;
  font-weight: 800;
  line-height: 40px; /* 157.168% */
  margin-top: 25px;
}

.home-sec3-card-div22 p {
  color: #b5b5b5;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.2; /* 525.518% */
  margin-top: 7px;
}























.home-idea-div1 {
  padding-top: 70px;
  padding-bottom: 55px;
}

.home-idea-text-div1 {
    display: flex;
    justify-content: center;
}

.home-idea-text-div2 h2 {
  color: #0c0c0d;
  font-family: "Bebas Neue";
  font-size: 36px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px; /* 87.34% */
  text-align: center;
}

.home-idea-text-div2 h3 {
  color: #f7b53c;
  font-family: "Bebas Neue";
  font-size: 36px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px; /* 102.625% */
  text-align: center;
}


.home-idea-row-div11 {
  row-gap: 12px;
  margin-top: 35px;
}

.home-idea-row-div11 .col-lg-3 {
  padding-left: 5px !important;
  padding-right: 5px !important;
}

.home-idea-img-div11 {
 height: 110px;
}

.home-idea-img-div11 img {
  /* min-height: 100%; */
  height: auto;
  max-width: 100%;
}

.home-idea-btn-div11 {
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 20.642px;
  z-index: 3;
  text-transform: uppercase;
}


.home-much-more{
  color: #000;  
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px; /* 102.625% */
  text-align: center;
  margin-top: 40px;
text-decoration: underline;
}





}





