.air-banner-div1 {
  background-image: url(../Images/image\ 57.jpg);
  min-height: 86vh;
  background-repeat: no-repeat;
  background-size: cover;
  /* background-position-x: -60px; */
  padding-top: 165px;
  position: relative;
}

.sea-banner-div1 {
  background-image: url(../Images/large-cargo-ship-with-containers-port-generative-ai\ 1.jpg);
  min-height: 86vh;
  background-repeat: no-repeat;
  background-size: cover;
  /* background-position-x: -60px; */
  padding-top: 165px;
  position: relative;
}

.sea-banner-para1 {
  color: #d7d7d7;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 137.5% */
  padding-right: 50px;
}

.sea-banner-stars-div1 {
  border-radius: 50px;
  border: 1px solid #454545;
  background: rgba(37, 37, 37, 0.41);
  backdrop-filter: blur(12px);
  width: 300px;
  padding: 16px 27px 15px 25px;
  column-gap: 10px;
}

.sea-banner-stars-div1 div {
  display: flex;
  flex-shrink: 0 !important;
  align-items: center;
  column-gap: 10px;
}

.sea-banner-stars-div1 div p {
  color: #fff;
  text-align: center;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.1; /* 985.591% */
  margin-bottom: 0px;
  margin-top: 0px !important;
}

.sea-banner-stars-div1 p:last-child {
  color: #fff;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.1; /* 985.591% */
  margin-bottom: 0px;
  margin-top: 8px;
}

.sea-banner-card-maindiv1 {
  min-height: 100%;
  position: absolute;
  right: 0px;
  bottom: 0px;
}

.sea-banner-card-div1 {
  border-top: 2.196px solid #f7b53c;
  border-right: 2.196px solid #f7b53c;
  border-left: 2.196px solid #f7b53c;
  background: #fff;
  box-shadow: 0px -40px 31.2px 0px rgba(0, 0, 0, 0.1);
  width: 500px;
  padding: 40px 60px 60px 60px;
  position: absolute;
  bottom: 0px;
}

.sea-banner-card-div1 h2 {
  color: #000;
  font-family: "Bebas Neue";
  font-size: 40.593px;
  font-style: normal;
  font-weight: 400;
  line-height: 40.02px; /* 162.64% */
  margin-bottom: 0px !important;
  display: flex;
  align-items: center;
  margin-top: 37px;
}

.sea-banner-card-div1 h2 span {
  color: #000;
  font-family: "Bebas Neue";
  font-size: 31.894px;
  font-style: normal;
  font-weight: 400;
  line-height: 51.873px; /* 162.64% */
}

.sea-banner-card-div1 h2 div {
  width: 11.664px;
  min-height: 29px;
  margin-right: 10px;
  background: #f7b53c;
  flex-shrink: 0;
  margin-top: -4px;
}

.sea-banner-card-div1 ul {
  margin-top: 20px;
  padding-left: 20px;
}

.sea-banner-card-div1 ul li {
  color: #000;
  font-family: Poppins;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: 25.415px; /* 467.15% */
  margin-top: 12px;
}

.sea-banner-card-div1 ul li span {
  color: #f7b53c;
  font-family: Poppins;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: 25.415px;
}

.sea-btn1 {
  color: #01070c;
  font-family: "Bebas Neue";
  font-size: 28.182px;
  font-style: normal;
  font-weight: 400;
  background: linear-gradient(
    95deg,
    #f7b53c 0.98%,
    #f7b53c 42.72%,
    #ffcd73 90.99%
  );
  padding: 12px 0px 10px 0px;
  min-width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 50px;
}

.sea-services-div12 {
  padding-top: 70px;
  padding-bottom: 100px;
}

.sea-services-text1 {
  color: #000;
  font-family: "Bebas Neue";
  font-size: 60px;
  font-style: normal;
  font-weight: 400;
  line-height: 61.13px; /* 101.884% */
}

.sea-services-text1 span {
  color: #f7b53c;
  font-family: "Bebas Neue";
  font-size: 60px;
  font-style: normal;
  font-weight: 400;
  line-height: 61.13px;
}

.sea-services-text2 {
  color: #1a1a1a;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: 27px; /* 150% */
  margin-bottom: 30px;
}

.sea-services-text-div1 {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.sea-services-text-div1 p {
  color: #1a1a1a;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: 27px; /* 150% */
}

.sea-services-text-div1 p svg {
  margin-right: 8px;
}

.sea-boat-img12 {
  margin-top: 60px;
  margin-bottom: 60px;
}

.sea-boat-para1 {
  color: #1a1a1a;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: 27px; /* 150% */
}

@media screen and (max-width: 576px) {
  .air-banner-div1 {
    background-image: url(../Images/image\ 40\ \(1\).jpg);
    min-height: 55vh;
    background-repeat: no-repeat;
    background-size: cover;
    /* background-position-x: -60px; */
    padding-top: 0px;
    position: relative;
    padding-left: 0px;
    padding-right: 0px;
  }

  .sea-banner-div1 {
    background-image: url(../Images/large-cargo-ship-with-containers-port-generative-ai\ 2.jpg);
    min-height: 55vh;
    background-repeat: no-repeat;
    background-size: cover;
    /* background-position-x: -60px; */
    padding-top: 0px;
    position: relative;
    padding-left: 0px;
    padding-right: 0px;
  }

  .sea-banner-stars-div1 {
    border-radius: 0px;
    border: 1px solid #454545;
    background: #003135;
    width: 100%;
    padding: 15px 0px 15px 0px;
    column-gap: 10px;
    margin-bottom: 65px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .sea-banner-stars-div1 div {
    display: block;
  }

  .sea-banner-stars-div1 p {
    color: #fff;
    text-align: center;
    font-family: Poppins;
    font-size: 10px;
  }

  .sea-banner-card-maindiv1 {
    min-height: 100%;
    position: absolute;
    bottom: -275px;
    right: 0px;
    text-align: center;
    padding: 20px !important;
  }

  .sea-banner-card-div1 {
    border-top: 2.196px solid #f7b53c;
    border-right: 2.196px solid #f7b53c;
    border-left: 2.196px solid #f7b53c;
    background: #fff;
    box-shadow: 0px -40px 31.2px 0px rgba(0, 0, 0, 0.1);
    width: 100%;
    padding: 35px 20px 0px 20px;
    position: relative;
    bottom: 0px;
    /* margin-left: 10px; */
    /* margin-right: 15px; */
  }

  .sea-banner-card-svgdiv1 {
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 15px;
  }

  .sea-banner-card-svgdiv1 h4 {
    color: #000;
    font-family: "Bebas Neue";
    font-size: 30px !important;
    font-style: normal;
    font-weight: 400;
    line-height: 25.034px !important; /* 72.584% */
    margin-bottom: 0px !important;
  }

  .sea-banner-card-div1 h2 {
    font-size: 60px;
    line-height: 40.02px; /* 162.64% */
    display: flex;
    align-items: end;
    margin-top: 20px;
    justify-content: center;
    text-align: center;
  }

  .sea-banner-card-div1 h2 span {
    font-size: 20px;
    line-height: 20px; /* 162.64% */
  }

  .sea-banner-card-div1 h2 div {
    display: none;
  }

  .sea-banner-card-div1 ul {
    margin-top: 10px;
    padding-left: 10px;
    list-style-position: inside;
    display: flex;
    justify-content: space-evenly;
    list-style: none;
  }

  .sea-banner-card-div1 ul li {
    font-size: 10px;
    line-height: 17px; /* 467.15% */
    margin-top: 12px;
  }

  .sea-banner-card-div1 ul li span {
    font-size: 10px;
    line-height: 17px;
  }

  .sea-btn1 {
    color: #01070c;
    font-family: "Bebas Neue";
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    background: linear-gradient(
      95deg,
      #f7b53c 0.98%,
      #f7b53c 42.72%,
      #ffcd73 90.99%
    );
    padding: 11px 0px 7px 0px;
    min-width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
  }

  .sea-services-div12 {
    padding-top: 190px;
    padding-bottom: 50px;
  }

  .sea-services-text1 {
    font-size: 34px;
    line-height: 34px; /* 101.884% */
  }

  .sea-services-text1 span {
    font-size: 34px;
    line-height: 34px;
  }

  .sea-services-text2 {
    font-size: 12px;
    line-height: 20px; /* 150% */
    margin-bottom: 20px;
  }

  .sea-services-text-div1 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  .sea-services-text-div1 p {
    font-size: 12px;
    line-height: 18px; /* 150% */
  }

  .sea-services-text-div1 p svg {
    margin-right: 7px;
  }

  .sea-boat-img12 {
    margin-top: 40px;
    margin-bottom: 40px;
  }

  .sea-boat-para1 {
    font-size: 12px;
    line-height: 20px; /* 150% */
  }
}
