.contact-mains-div1{
    overflow: hidden;
}


.contact-div1 {
    background: #00212A;
  min-height: 86vh;
  background-repeat: no-repeat;
  background-size: cover;
  /* background-position-x: -60px; */
  padding-top: 165px;
  position: relative;
}

.contact-div1 .container.position-relative{
    min-height: 65vh
}

.contact-us-form-main-div1{
    position: absolute;
    bottom: -30px;
    right: 0px;
    display: flex;
    justify-content: end;
}

.contact-form-div55{
    position: relative;
    z-index: 4;
    width:500px;
}

.contact-truck-img-div1{

}

.contact-truck-img-div1 div img{

   position: absolute;
bottom: -23px;

z-index: 1;
transform: rotate(-1deg);
}



















@media screen and (max-width: 576px) {
.contact-div1 {
    background: #00212A;
  /* background-image: url(../Images/image\ 40\ \(1\).jpg); */
  min-height: 55vh;
  background-repeat: no-repeat;
  background-size: cover;
  /* background-position-x: -60px; */
  padding-top: 0px;
  position: relative;
  padding-left: 0px;
  padding-right: 0px;
}


.contact-us-form-main-div1{
    position: absolute;
    bottom: -177px;
    right: 0px;
    display: flex;
    justify-content: end;
}


.contact-banners-truck-img{
    margin-top: 13px;
    /* margin-bottom: 30px; */
}

.for-mobile-contact-sec3-div{
    margin-top: 220px;
}

}



@media screen and (max-width: 380px) {
    .contact-div1 div div div div div .home-banner-text1 br{
        display: none;
    }
}





.contact-truck-animation{
    animation: movesTruck1 5s linear forwards; /* Total animation time increased to accommodate the stop */
  }

  @keyframes movesTruck1 {
    0%{
left: 500px;
    }
    100% {
        left: -200px;
    }
  }




